<script lang="ts" setup>
import { useTheme } from "vuetify";
import type { TSvgName } from "~/lib/svg";

const { themes } = useTheme();
const { medica, diag, dental, estetis } = themes.value.customTheme.colors;

const links = [
    { title: "www.allmedica.pl", url: "https://allmedica.pl", imageName: "allmedica-logo-vertical" as TSvgName, color: medica },
    { title: "www.alldental.pl", url: "https://alldental.pl", imageName: "alldental-logo-vertical" as TSvgName, color: dental },
    { title: "www.allestetis.pl", url: "https://allestetis.pl", imageName: "allestetis-logo-vertical" as TSvgName, color: estetis },
    { title: "www.alldiag.pl", url: "https://alldiag.pl", imageName: "alldiag-logo-vertical" as TSvgName, color: diag }
];
</script>
<template>
    <SectionWrapper class="bg-white">
        <h2 class="d-flex justify-center">
            <DecoratedTitle
                class="mx-auto text-center text-sm-left title-wrapper"
                text="Sister Companies"
                decorated-phrase="Companies"
                prepend-icon="decorator-1"
                prepend-icon-size=".75em"
                prepend-icon-position="-90% -50%"
                prepend-icon-animation-class="shake"
            />
        </h2>
        <VRow class="mt-4 mt-md-15 mt-lg-24">
            <VCol v-for="link in links" :key="link.url" cols="3" class="fill-height">
                <NuxtLink :to="link.url" target="_blank" :title="link.title">
                    <Svg :name="link.imageName" width="100%" :style="`opacity: 0.3; --color: ${link.color}`"></Svg>
                </NuxtLink>
            </VCol>
        </VRow>
    </SectionWrapper>
</template>

<style lang="scss" scoped>
:deep(svg) {
    height: 1.7rem !important;
    transition:
        opacity 0.2s ease-in-out,
        fill 0.2s ease-in-out;

    &:hover {
        opacity: 1 !important;
        fill: var(--color);
    }

    @media screen and (min-width: 600px) {
        height: 3.5rem !important;
    }

    @media screen and (min-width: 960px) {
        height: 4.8rem !important;
    }

    @media screen and (min-width: 1280px) {
        height: 6.25rem !important;
    }
}
</style>
